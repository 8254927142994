<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->

                <b-col
                  cols="12"
                  xl="8"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Sprzedawca:
                  </h6>

                  <strong>  InFakt Sp z o.o.</strong>
                  <p class="mb-0 pb-0">
                    Kącik 4 ,   30-549 Kraków
                  </p>

                  <p class="mb-0 pb-0">
                    NIP: 945-212-16-81
                  </p>
                  <p class="mb-0 pb-0">
                    Kraj: Polska
                  </p>

                  <p class="mt-1pb-0">

                    <b-badge

                      pill
                      variant="success"
                    >
                      VAT
                    </b-badge>
                    Podmiot jest czynnym podatnikiem VAT
                  </p>

                  <!-- Selected Client -->
                  <div

                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      <!-- {{ invoiceData.client.name }} -->
                    </h6>
                    <b-card-text class="mb-25">
                      <!-- {{ invoiceData.client.company }} -->
                    </b-card-text>
                    <b-card-text class="mb-25">
                      <!-- {{ invoiceData.client.address }}, {{ invoiceData.client.country }} -->
                    </b-card-text>
                    <b-card-text class="mb-25">
                      <!-- {{ invoiceData.client.contact }} -->
                    </b-card-text>
                    <b-card-text class="mb-0">
                      <!-- {{ invoiceData.client.companyEmail }} -->
                    </b-card-text>
                  </div>
                </b-col>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Invoice
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"

                        disabled
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Date:
                    </span>
                    <flat-pickr

                      class="form-control invoice-edit-input"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title">
                      Due Date:
                    </span>
                    <flat-pickr

                      class="form-control invoice-edit-input"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            />

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{height: trHeight}"
              >
                <b-row

                  :key="index"
                  ref="row"
                  class="pb-2"
                >

                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">

                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Stawka VAT
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Wartość netto
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Kwota VAT
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Wartość brutto
                        </b-col>

                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Stawka VAT</label>
                          <b-form-input

                            type="text"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Wartość netto	</label>
                          <b-form-input

                            type="text"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Kwota VAT</label>
                          <b-form-input

                            type="text"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Wartość brutto</label>
                          <b-form-input

                            type="text"
                            class="mb-2"
                          />
                        </b-col>

                      </b-row>

                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="d-none"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Add Item
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="2"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                />

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="10"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        $1800
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Discount:
                      </p>
                      <p class="invoice-total-amount">
                        $28
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Tax:
                      </p>
                      <p class="invoice-total-amount">
                        21%
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        $1690
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- zalacznik -->

            <b-card-body class="invoice-padding pt-0 ">
              <h6 class="pb-1">
                Dodaj załącznik
              </h6>
              <b-form-file
                v-model="file"
                placeholder="Przeciągnij i upuść tutaj dokumenty w formacie pdf, skany lub zdjęcia"
                drop-placeholder="Przeciągnij i upuść tutaj dokumenty w formacie pdf, skany lub zdjęcia"
              />

            </b-card-body>

            <!-- zalacznik -->

            <b-card-body class="invoice-padding pt-0 ">
              <h6 class="pb-1">
                Lista załączników
              </h6>
              <b-link>document.pdf</b-link>

            </b-card-body>

          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            disabled
          >
            Send Invoice
          </b-button>

          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
          >
            Preview
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
          >
            Save
          </b-button>
        </b-card>

        <!-- Status-->

        <b-form-group
          label="Kategoria kosztu"
          label-for="cost-category"
        >
          <v-select

            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

            input-id="cost-category"
            class="payment-selector"
            :clearable="false"
          />
        </b-form-group>

        <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->

        </div>
      </b-col>
    </b-row>
    <invoice-sidebar-add-new-customer />
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  BRow, BCol, BAlert, BFormFile, BCard, BBadge, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import InvoiceSidebarAddNewCustomer from '../invoice/InvoiceSidebarAddNewCustomer.vue'

export default {
  components: {
    BBadge,
    BAlert,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BFormFile,
    flatPickr,
    vSelect,
    Logo,
    InvoiceSidebarAddNewCustomer,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  computed: {
    singleCost() {
      return this.$store.state.costs[0]
    },
  },
  mounted() {

  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {

  },
  setup() {
    const itemsOptions = [
      {
        itemTitle: 'App Design',
        cost: 24,
        qty: 1,
        description: 'Designed UI kit & app pages.',
      },
      {
        itemTitle: 'App Customization',
        cost: 26,
        qty: 1,
        description: 'Customization & Bug Fixes.',
      },
      {
        itemTitle: 'ABC Template',
        cost: 28,
        qty: 1,
        description: 'Bootstrap 4 admin template.',
      },
      {
        itemTitle: 'App Development',
        cost: 32,
        qty: 1,
        description: 'Native App Development.',
      },
    ]

    const paymentMethods = [
      'Bank Account',
      'PayPal',
      'UPI Transfer',
    ]

    const paymentCurrency = [
      'PLN',
      'EUR',

    ]

    const paymentTimePeriod = [
      '7 dni',
      '14 dni',
      '30 dni',
    ]

    const invoiceStatus = [
      'Wystawiona',
      'Opłacona',
      'Wysłana',
    ]

    const CostCategory = [
      'Dokumenty biurowe',
      'Paliwo',
      'Delegacja',
    ]

    const invoiceLanguage = [
      'Polski',
      'Angielski',
    ]

    const invoicePeriod = [
      'Nie ponawiaj',
      'Miesiac',
      'Kwartal',
    ]

    return {

      itemsOptions,
      paymentMethods,
      paymentCurrency,
      paymentTimePeriod,
      invoiceStatus,
      invoiceLanguage,
      invoicePeriod,
      CostCategory,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
